const loginForm = document.getElementById('new_user_session');
if (loginForm) {
  loginForm.action = loginForm.action + window.location.hash;
}

let localeMenuToggler, localeMenu, localeMenuItems;
let menuOpenedByHover = false;

document.addEventListener('readystatechange', () => {
  if (document.readyState === "complete") {
    localeMenuToggler = document.querySelector('.js-locale-menu-toggler');
    localeMenu = document.querySelector('.js-locale-menu');

    if (localeMenuToggler) {
      localeMenuToggler.addEventListener('click', function () {
        if (!menuOpenedByHover) {
          toggleMenu(this, !(this.getAttribute('aria-expanded') === 'true' || false), true);
        }
      });
      localeMenuToggler.addEventListener('mouseenter', function () {
        menuOpenedByHover = true;
        toggleMenu(this, true);
      });
      localeMenuToggler.addEventListener('focus', function () {
        toggleMenu(this, true);
      });
      localeMenuToggler.addEventListener('mouseleave', collapse);
      localeMenuToggler.addEventListener('blur', collapse);
    }

    if (localeMenu && localeMenuToggler) {
      localeMenuItems = localeMenu.querySelectorAll('ul > li a');

      if (localeMenuItems) {
        localeMenuItems[0].addEventListener('blur', closeMenuOnBlur)
        localeMenuItems[localeMenuItems.length - 1].addEventListener('blur', closeMenuOnBlur)
      }

      localeMenu.addEventListener('mouseleave', function (e) {
        const toggler = localeMenu.previousElementSibling;
        if (menuOpenedByHover && !toggler.isSameNode(e.relatedTarget)) {
          menuOpenedByHover = false;
          toggleMenu(toggler, false);
        }
      })
    }
  }
})

const toggleMenu = function (toggler, expand) {
  toggler.setAttribute('aria-expanded', expand);
  const menu = toggler.nextElementSibling;
  menu.hidden = !expand;
  if (expand) {
    menu.classList.remove('hidden');
  } else {
    menu.classList.add('hidden');
  }
}
const collapse = function (e) {
  if (!isWithinNode(e.relatedTarget, localeMenu)) {
    menuOpenedByHover = false;

    toggleMenu(e.target, false);
  }
}

const isWithinNode = function (current, reference) {
  while (current) {
    if (current.isSameNode(reference)) {
      return true;
    }

    current = current.parentElement;
  }

  return false;
}

// collapse a sub-menu when the user tabs out of it
const closeMenuOnBlur = function (e) {
  if (!isWithinNode(e.relatedTarget, localeMenu)) {
    toggleMenu(localeMenuToggler, false);
  }
};